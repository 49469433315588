import { Elements } from '@stripe/react-stripe-js'
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import { getClientSecret } from 'api/profiles'
import { ReactNode, createContext, useEffect, useState } from 'react'
import { UseQueryResult, useQuery } from 'react-query'

type Props = {
  children: ReactNode
}

type State = {
  clientSecret: string
  refetch: UseQueryResult['refetch']
}

export const StripeSetupContext = createContext<State>({} as State)

export const StripeSetupProvider = ({ children }: Props) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>()

  const { data: clientSecret, refetch } = useQuery(
    ['clientSecret', stripePromise],
    async () => {
      if (!stripePromise) return

      const { clientSecret: initialClientSecret } = await getClientSecret()

      if (!initialClientSecret) {
        console.error('Failed to fetch client secret')
        return
      }

      return initialClientSecret
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: Boolean(stripePromise),
      keepPreviousData: false,
    }
  )

  useEffect(() => {
    const load = async () => {
      const { loadStripe } = await import('@stripe/stripe-js/pure')

      setStripePromise(
        loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string)
      )
    }

    load()

    return () => {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!stripePromise || !clientSecret) {
    return null
  }

  const options: StripeElementsOptions = {
    clientSecret,
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700',
        family: 'Poppins',
        style: 'normal',
      },
    ],
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        colorBackground: '#ffffff',
        spacingGridRow: '0.5rem',
      },
      rules: {
        '.Input': {
          border: '1px solid #EFF0F6',
        },
        '.Input::placeholder': {
          color: '#6E7191',
        },
        '.Label': {
          color: '#6E7191',
        },
      },
    },
  }

  return (
    <StripeSetupContext.Provider value={{ clientSecret, refetch }}>
      <Elements options={options} stripe={stripePromise}>
        {children}
      </Elements>
    </StripeSetupContext.Provider>
  )
}
