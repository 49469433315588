/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import classNames from 'classnames'
import { CenteredSpinner } from 'components/common/CenteredSpinner'
import routes from 'constants/routes'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { HTMLProps, useCallback, useEffect, useRef, useState } from 'react'
import { waitForElement } from 'utils/interactions'
import { useDrawer, useLoading, useOverlay } from '../../contexts/hooks'

const APP_ID = 'xug11f80'

const intercomOptions = {
  app_id: APP_ID,
  custom_launcher_selector: '#chat-with-team-btn',
}

const load = () =>
  (function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i: any = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args: any) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${APP_ID}`
        var x: any = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()

const boot = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom('boot', {
      ...intercomOptions,
      ...options,
    })
}

const update = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom('update', { ...intercomOptions, ...options })
}

const hide = () => {
  if (window && window.Intercom) {
    window.Intercom('hide')
    update({ hide_default_launcher: true })
  }
}

const unhide = () => {
  update({ hide_default_launcher: false })
}

const show = () => {
  if (window && window.Intercom) {
    unhide()
    window.Intercom('show')
  }
}

const shouldHide = (url: string) => {
  return (
    url.includes('ask-or-sell') ||
    url.includes('bid-or-buy') ||
    url.startsWith(`${routes.bulkUpload.base}/`)
  )
}

type IntercomTriggerProps = Omit<
  HTMLProps<HTMLButtonElement>,
  'type' | 'onClick' | 'onMouseOver'
> & {
  defaultMessage?: string
}

export const IntercomTrigger = ({
  children,
  defaultMessage,
  ...props
}: IntercomTriggerProps) => {
  const { t } = useTranslation('common')
  const { isIntercomLoaded, setIsIntercomLoaded } = useLoading()
  const [isLoading, setIsLoading] = useState(false)

  const loadHandler = useCallback(() => {
    if (!isIntercomLoaded && typeof window !== 'undefined') {
      load()
      boot()

      setIsLoading(true)

      waitForElement('#intercom-container', 30000).then(elements => {
        console.log('intercom container', elements)
        setIsIntercomLoaded(true)
        setIsLoading(false)
      })

      if (defaultMessage) {
        window.Intercom('showNewMessage', defaultMessage)
      }
    }
  }, [isIntercomLoaded, defaultMessage])

  const onClick = useCallback(() => {
    loadHandler()
    show()
  }, [loadHandler])

  const onMouseOver = useCallback(() => {
    loadHandler()
  }, [loadHandler])

  return (
    <button
      type='button'
      {...props}
      className={classNames('[&>*]:pointer-events-none', props.className)}
      onClick={onClick}
      onMouseOver={onMouseOver}
      // onPointerDown={onPointerDown}
      id='custom-intercom-trigger'
    >
      <div className='relative w-full h-full flex items-center justify-center'>
        <p className='sr-only'>{t('open_intercom')}</p>

        {children}

        {isLoading && <CenteredSpinner />}
      </div>
    </button>
  )
}

export const Intercom = () => {
  const router = useRouter()
  const [isHidden, setIsHidden] = useState(false)
  const { isIntercomLoaded } = useLoading()
  const { isOpen: overlayOpen } = useOverlay()
  const { isOpen: drawerOpen } = useDrawer()
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)

  const hideHandler = () => {
    setIsHidden(true)
    hide()
  }

  const unhideHandler = () => {
    setIsHidden(false)
    unhide()
  }

  const timeoutHandler = () => {
    return setTimeout(() => {
      // loadHandler()
    }, 5000)
  }

  const clearTimeoutHandler = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  useEffect(() => {
    if (!router.isReady) return

    if (shouldHide(router.asPath)) {
      hideHandler()
      return
    }

    timeout.current = timeoutHandler()

    return () => {
      clearTimeoutHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  useEffect(() => {
    if (overlayOpen || drawerOpen) {
      hideHandler()
    } else if (!shouldHide(router.asPath)) {
      unhideHandler()
    }
  }, [overlayOpen, drawerOpen, router.asPath])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window !== 'undefined') {
        if (shouldHide(url)) {
          hideHandler()
          clearTimeoutHandler()
        } else {
          if (!isIntercomLoaded) {
            timeout.current = timeoutHandler()
          }
          unhideHandler()
        }
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      clearTimeoutHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, isIntercomLoaded])

  return (
    <div id='intercom'>
      {!isHidden && (
        <IntercomTrigger
          style={{
            backgroundColor: '#6366C9',
            boxShadow:
              '0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%)',
          }}
          className='fixed bottom-5 right-5 z-50 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 28 32'
            width={24}
            height={24}
          >
            <path
              d='M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z'
              fill='#fff'
            ></path>
          </svg>
        </IntercomTrigger>
      )}
    </div>
  )
}
